import React from 'react'
import logoArab from '../images/logoArab.png';
import logoFrench from '../images/logoFrench.png';
import logoEnglish from '../images/logoEnglish.png';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageDropDown from '../components/LanguageDropDown';


const currentYear = new Date().getFullYear();

function Footer() {
    const { t } = useTranslation()
     const savedLanguage = localStorage.getItem('i18nextLng') || process.env.REACT_APP_DEFAULT_LANGUAGE;
    const language = process.env.REACT_APP_LANGUAGE_SWITCH;




    const getImage = () => {
        if (savedLanguage == 'ar') {
            return logoArab
        } else if (savedLanguage == 'fr') {
            return logoFrench
        } else if (savedLanguage == 'en' && language === 'arabic') {
            return logoArab

        } else if (savedLanguage == 'en' && language !== 'arabic') {
            return logoEnglish
        }
    }

    return (
        <footer className="bg-footer">

            <div className='flex md:justify-between gap-4 items-center md:flex-row  px-4 md:py-16 py-12 mx-auto  xl:px-[7rem] lg:px-[4rem] md:px-[2rem] px-5 flex-col pb-10 border-b border-white/10'>
                <h1 className='md:text-[40px] text-[30px] font-[800] leading-[80px] text-gray-100'>
                    {t("Let's Stay connected")}
                </h1>

                <div className='flex justify-end items-center gap-3 '>
                    <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-300 border md:px-5 px-2.5 py-2.5 rounded-full bg-white/10 text-gray-300  font-[400]   md:text-[16px] text-[14px] w-fit '>
                        <span>{t("Let’s Get Started")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                    </Link>
                    <a href={process.env.REACT_APP_DACHBOARD_LINK} className='flex justify-center items-center gap-2 capitalize border-primary border md:px-5 px-2.5 py-2.5 rounded-full bg-secondary text-primary font-[500]   md:text-[16px] text-[14px] w-fit'>
                        <span>{t("Dashboard")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                            <path d="M7 7h10v10" />
                            <path d="M7 17 17 7" />
                        </svg>
                    </a>
                </div>


            </div>


            <div className=" xl:px-[7rem] lg:px-[4rem] md:px-[2rem] px-5 py-16 mx-auto ">
                <div className=" gap-8  w-full flex md:flex-row flex-col md:justify-start md:items-center justify-center items-center">
                    <div className='flex md:w-2/5 gap-4 flex-col justify-center md:items-start items-center'>
                        <img src={getImage()} alt='logo' objectFit="cover" className='w-56' />
                        <p className=" mb-4 md:text-start text-center font-[400] text-[16px] text-gray-300">
                            {t("Footer description")}
                        </p>
                        {
                            language === 'arabic' &&
                            <LanguageDropDown />

                        }

                    </div>
                    <div className="flex md:w-4/5 md:flex-row flex-col md:justify-start md:items-start justify-end w-full items-center text-[22px]  text-gray-200  gap-8">
                        <div className='flex flex-col md:w-1/4 w-full justify-start md:items-start items-center'>
                            <p className="font-[700]">
                                {t("Pages")}
                            </p>
                            <nav className="flex flex-col md:items-start items-center mt-4 space-y-2 text-[17px] text-gray-300 ">
                                <Link to='/Pricing' className=" hover:text-secondary cursor-pointer" > {t("Pricing")}</Link>
                                <Link to='/Contact' className=" hover:text-secondary cursor-pointer" > {t("Contact Us")} </Link>
                                <Link to='/Blog' className=" hover:text-secondary cursor-pointer" > {t("Blog's")} </Link>
                            </nav>
                        </div>
                        <div className='flex flex-col md:w-1/4 w-full justify-start md:items-start items-center'>
                            <p className="font-[700]">
                                {t("Services")}
                            </p>
                            <nav className="flex flex-col md:items-start items-center mt-4 space-y-2 text-[17px] text-gray-300 ">
                                <Link to='/link-building' className=" hover:text-secondary cursor-pointer" > {t("LinkBuilding")} </Link>
                                <Link to='/guest-blogging' className=" hover:text-secondary cursor-pointer" > {t("Guest Blogging")} </Link>
                                <Link to='/content-writing' className=" hover:text-secondary cursor-pointer" > {t("Content Writing")} </Link>
                                {/* <Link to='/other-services' className=" hover:text-secondary cursor-pointer" > {t("Other Services ")} </Link> */}

                            </nav>
                        </div>
                        <div className='flex flex-col md:w-1/4 w-full justify-start md:items-start items-center'>
                            <p className="font-[700]">
                                {t("Helpful Links")}
                            </p>
                            <nav className="flex flex-col md:items-start items-center mt-4 space-y-2 text-[17px] text-gray-300 ">
                                <Link to='/Contact' className=" hover:text-secondary cursor-pointer" > {t("Contact")} </Link>
                                <a className=" hover:text-secondary cursor-pointer" href="#faq"> {t("FAQs")} </a>
                            </nav>
                        </div>
                        <div className='flex flex-col md:w-1/4 w-full justify-start md:items-start items-center'>
                            <p className="font-[700]">
                                {t("Legal")}
                            </p>
                            <nav className="flex flex-col md:items-start items-center mt-4 space-y-2 text-[17px] text-gray-300 ">
                                <Link to='/Privacy-Policy' className=" hover:text-secondary cursor-pointer" href> {t("Privacy Policy")} </Link>
                                <Link to='/Terms-Conditions' className=" hover:text-secondary cursor-pointer" href> {t("Terms & Conditions")} </Link>
                            </nav>
                        </div>
                    </div>
                </div>

            </div>


            <div className='flex  xl:px-[7rem] lg:px-[4rem] md:px-[2rem] px-5 md:justify-between items-center md:flex-row   px-4 pb-14 mx-auto sm:px-6 lg:px-8 flex-col  border-t border-white/10'>
                <p className="mt-8 text-[15px] font-[300] text-gray-300">
                    © {currentYear} {process.env.REACT_APP_SITE_NAME} 
                </p>

            </div>
        </footer>

    )
}

export default Footer