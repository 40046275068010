import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import photo from '../images/photo.jpg';
import { useTranslation } from 'react-i18next';

function Terms() {
    const { pathname } = useLocation();
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scroll smooth
        });
    }, [pathname]);

    return (
        <div>
            <div className='w-full h-fit   relative flex justify-center items-center' >
                <div className="absolute inset-0 bg-primary z-10 opacity-60"></div>
                <div
                    className="absolute inset-0 bg-cover rotate-180 bg-center"
                    style={{ backgroundImage: `url(${photo})` }}
                ></div>



                {/* Content Section */}
                <div className='flex z-20 flex-col py-24 justify-center 2xl:w-[50%] xl:w-[60%] lg:w-[70%] md:w-[80%] text-center w-full mt-12 md:mt-24
             mx-auto px-3 items-center gap-10 '>

                    <h1 className='md:text-[48px] text-[30px]  font-[800] leading-[40px] capitalize md:leading-[80px] text-gray-100'>
                        {t("Terms & Conditions ")}
                    </h1>

                    <div className='flex justify-center items-center gap-3 w-full'>
                        <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-300 border md:px-5 px-2.5 py-2.5 rounded-full bg-white/10 text-gray-300  font-[400]   md:text-[16px] text-[14px] w-fit '>
                            <span>{t("Let’s Get Started")}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                        </Link>
                        <a href={process.env.REACT_APP_DACHBOARD_LINK} className='flex justify-center items-center gap-2 capitalize border-primary border md:px-5 px-2.5 py-2.5 rounded-full bg-secondary text-primary font-[500]   md:text-[16px] text-[14px] w-fit'>
                            <span>{t("Dashboard")}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                                <path d="M7 7h10v10" />
                                <path d="M7 17 17 7" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>


            <div className="bg-gray-50 py-12 px-4 lg:px-16">
                <div className="max-w-6xl mx-auto md:p-8">
                    <h1 className="text-3xl font-bold text-gray-800 mb-6">
                        {t("Terms & Conditions")}
                    </h1>
                    <p className="text-gray-600 mb-6">
                        {t("Welcome to")} {process.env.REACT_APP_SITE_NAME} . {t("By accessing or using our website, you agree to comply with and be bound by the following terms and conditions of use. Please read them carefully.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">
                        1. {t("Acceptance of Terms")}
                    </h2>
                    <p className="text-gray-600 mb-4">
                        {t("By using this website, you confirm that you accept these terms and agree to comply with them. If you do not agree with any part of these terms, please do not use our site.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">
                        2. {t("Changes to Terms")}
                    </h2>
                    <p className="text-gray-600 mb-4">
                        {t("We reserve the right to update or modify these terms at any time without prior notice. Your continued use of the website following the changes signifies your acceptance of the revised terms.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">
                        3. {t("Use of Website")}
                    </h2>
                    <p className="text-gray-600 mb-4">
                        {t("You agree to use our website for lawful purposes only and in a way that does not infringe the rights of, restrict, or inhibit anyone else’s use and enjoyment of the site. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue on our site.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">
                        4. {t("Intellectual Property")}
                    </h2>
                    <p className="text-gray-600 mb-4">
                        {t("All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of")} {t("[Your Company]")} {t("or its content suppliers and is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, modify, distribute, or use any content without our prior written consent.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">
                        5. {t("Limitation of Liability")}
                    </h2>
                    <p className="text-gray-600 mb-4">
                        {t("We will not be liable for any damages arising from the use of this website. This includes but is not limited to direct, indirect, incidental, punitive, and consequential damages.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">
                        6. {t("External Links")}
                    </h2>
                    <p className="text-gray-600 mb-4">
                        {t("Our website may include links to external websites for your convenience. We are not responsible for the content or availability of these external sites, and your use of these links is entirely at your own risk.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">
                        7. {t("Termination of Use")}
                    </h2>
                    <p className="text-gray-600 mb-4">
                        {t("We reserve the right to terminate your access to the website if you violate these terms or engage in any unlawful activities.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">
                        8. {t("Governing Law")}
                    </h2>
                    <p className="text-gray-600 mb-4">
                        {t("These terms are governed by and construed in accordance with the laws of")} {process.env.REACT_APP_SITE_NAME} ,   {t("for the resolution of any disputes.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">
                        9. {t("Contact Information")}
                    </h2>
                    <Link to='/Contact' className="text-blue-600  mb-4">
                        {t("If you have any questions about these Terms and Conditions, please contact us at.")}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Terms