import React from 'react';
import photo from '../../images/photo.jpg';
import { useTranslation } from 'react-i18next';

function Hero() {
                const { t } = useTranslation()

    return (
        <div className='w-full h-fit  relative flex justify-center items-center' >
            <div className="absolute inset-0 bg-primary z-10 opacity-60"></div>
            <div
                className="absolute inset-0 bg-cover rotate-180 bg-center"
                style={{ backgroundImage: `url(${photo})` }}
            ></div>




            {/* Content Section */}
            <div className='flex z-20 flex-col p-24 justify-center 2xl:w-[50%] xl:w-[60%] lg:w-[70%] md:w-[80%] text-center w-full mt-12 md:mt-24 mx-auto px-3 items-center gap-5 '>

                <h1 className='md:text-[48px] text-[30px]  font-[800] leading-[40px] md:leading-[80px] text-gray-100'>
                    {t("Get in Touch with Us")}
                </h1>
                <p className='md:text-[18px] text-[14px] font-[400] leading-[25px] md:leading-[40px] text-gray-100'>
                    {t("We're here to elevate your online presence. If you have questions, need assistance, or want to discuss a project, reach out! Fill out the form below, and we'll respond promptly. Your success is our priority!")}                </p>
                <div className='flex justify-center items-center gap-3 w-full'>

                    <a href={process.env.REACT_APP_DACHBOARD_LINK} className='flex justify-center items-center gap-2 capitalize border-secondary border px-5 py-2.5 rounded-full bg-secondary text-black font-[500]   md:text-[16px] text-[14px] w-fit'>
                        <span>{t("Dashboard")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                            <path d="M7 7h10v10" />
                            <path d="M7 17 17 7" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Hero;
