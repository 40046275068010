import React, { useState, useEffect } from 'react';
import Hero from '../components/Blog/Hero'
import Faq from '../components/Faq';
import BlogCard from '../components/Blog/BlogCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function Blog() {
    const { t } = useTranslation()
    const savedLanguage = localStorage.getItem('i18nextLng') || process.env.REACT_APP_DEFAULT_LANGUAGE;
    const language = process.env.REACT_APP_LANGUAGE_SWITCH;



    const getUrl = () => {
        if(savedLanguage == 'ar'){
            return `backlinksarab/${savedLanguage}`
        }else if(savedLanguage == 'fr'){
           return 'seofocus'
        }else if (savedLanguage == 'en' && language === 'arabic'){
            return `backlinksarab/${savedLanguage}`

        }else if (savedLanguage == 'en' && language !== 'arabic'){
            return 'SmartTrafficBoost'
        }
    }

    const [blog, setBlog] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { pathname } = useLocation();

    useEffect(() => {
 window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scroll smooth
    });    }, [pathname]);


    useEffect(() => {
        getBlog(currentPage);
    }, [currentPage, savedLanguage]);

    const getBlog = async (page = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://api.dashforme.xyz/public/api/blogs/searchBlogs/${getUrl()}?page=${page}`);
            const responseData = response.data;

            if (responseData) {

                setBlog(responseData.data);
                setCurrentPage(responseData.current_page);
                setTotalPages(responseData.last_page);
                console.log('Fetched Blog Data:', responseData.data);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred while fetching the blogs.');
        } finally {
            setLoading(false);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    return (
        <div className="h-full w-full">
            <Helmet>
                <title>{t("Meta Title Blog")}</title>
                <meta name="description" content={t("Meta Description Blog")} />
            </Helmet>

            <Hero />

            <div className='flex md:py-24 py-10 w-full flex-col justify-center items-center gap-12'>

                <h1 className='md:text-[60px] mt-14 text-center text-[25px] font-[800] leading-[60px] capitalize md:leading-[80px] text-primary'>
                    {t("All Blog Posts")}
                </h1>
                <div className='md:gap-10 gap-4 grid xl:px-40 lg:px-20 md:px-10 px-3 md:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3'>
                    {loading ? (
                        <div className='md:col-span-3'>
                            <Loading />

                        </div>) : (
                        blog.map((post) => <BlogCard key={post.id} post={post} />)
                    )}
                </div>

                <div className="mt-6 flex w-full items-center justify-center gap-4">

                    <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
                        <button
                            onClick={handlePrevious}
                            disabled={currentPage === 1}
                            className="flex items-center justify-center w-fit px-2 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 "
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                            </svg>
                            <span>{t("Previous")}</span>
                        </button>
                        <div className="text-sm text-neutral-500 dark:text-neutral-400">
                            {t("Page")} <span className="font-medium text-neutral-800">{currentPage} of {totalPages}</span>
                        </div>
                        <button
                            onClick={handleNext}
                            disabled={currentPage === totalPages}
                            className="flex items-center justify-center w-fit px-2 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 "
                        >
                            <span>{t("Next")}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <Faq />
        </div>
    );
}

export default Blog;
