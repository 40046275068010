import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationAR from './locales/ar/translation'
import translationFR from './locales/fr/translation'
import translationEN from './locales/en/translation'

const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
    ar: {
        translation: translationAR,
    },
};

// const savedLanguage = 'ar';

const savedLanguage = localStorage.getItem('i18nextLng')  || process.env.REACT_APP_DEFAULT_LANGUAGE;

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: savedLanguage , // default language
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
