import React, { useEffect, useState } from 'react'

import Hero from "../components/Hero";
import WhyUs from "../components/WhyUs";
import Services from "../components/Services";
import Faq from "../components/Faq";
import Testimonials from "../components/Testimonials";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import BlogSection from '../components/BlogSection';
import { toast } from 'react-toastify';
import axios from 'axios';
export default function Home() {

    const { t } = useTranslation()
    const savedLanguage = localStorage.getItem('i18nextLng') || process.env.REACT_APP_DEFAULT_LANGUAGE;
    const language = process.env.REACT_APP_LANGUAGE_SWITCH;



    const getUrl = () => {
        if (savedLanguage == 'ar') {
            return `backlinksarab/${savedLanguage}`
        } else if (savedLanguage == 'fr') {
            return 'seofocus'
        } else if (savedLanguage == 'en' && language === 'arabic') {
            return `backlinksarab/${savedLanguage}`

        } else if (savedLanguage == 'en' && language !== 'arabic') {
            return 'SmartTrafficBoost'
        }
    }
    const { pathname } = useLocation();
    const [blog, setBlog] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scroll smooth
        });
    }, [pathname]);


    useEffect(() => {
        getBlog();
    }, [savedLanguage]);

    const getBlog = async (page = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://api.dashforme.xyz/public/api/blogs/searchBlogs/${getUrl()}?page=${page}`);
            const responseData = response.data;

            if (responseData) {

                setBlog(responseData.data);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred while fetching the blogs.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="h-full w-full">
     
            <Hero />
            <WhyUs />
            <Services />
            <Testimonials />
            <BlogSection blogs={blog}/>
            <Faq />

        </div>
    );
}
