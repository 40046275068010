import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import photo from '../images/photo.jpg';
import { useTranslation } from 'react-i18next';

function Privacy() {
    const { pathname } = useLocation();
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scroll smooth
        });
    }, [pathname]);

    return (
        <div>
            <div className='w-full h-fit   relative flex justify-center items-center' >
                <div className="absolute inset-0 bg-primary z-10 opacity-60"></div>
                <div
                    className="absolute inset-0 bg-cover rotate-180 bg-center"
                    style={{ backgroundImage: `url(${photo})` }}
                ></div>



                {/* Content Section */}
                <div className='flex z-20 flex-col py-24 justify-center 2xl:w-[50%] xl:w-[60%] lg:w-[70%] md:w-[80%] text-center w-full mt-12 md:mt-24
             mx-auto px-3 items-center gap-10 '>

                    <h1 className='md:text-[48px] text-[30px]  font-[800] leading-[40px] capitalize md:leading-[80px] text-gray-100'>
                        {t("Privacy Policy")}
                    </h1>

                    <div className='flex justify-center items-center gap-3 w-full'>
                        <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-300 border md:px-5 px-2.5 py-2.5 rounded-full bg-white/10 text-gray-300  font-[400]   md:text-[16px] text-[14px] w-fit '>
                            <span>{t("Let’s Get Started")}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                        </Link>
                        <a href={process.env.REACT_APP_DACHBOARD_LINK} className='flex justify-center items-center gap-2 capitalize border-primary border md:px-5 px-2.5 py-2.5 rounded-full bg-secondary text-primary font-[500]   md:text-[16px] text-[14px] w-fit'>
                            <span>{t("Dashboard")}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                                <path d="M7 7h10v10" />
                                <path d="M7 17 17 7" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>





            <div className="bg-gray-50 py-12 px-4  lg:px-16">
                <div className="max-w-6xl mx-auto md:p-8">
                    <h1 className="text-3xl font-bold text-gray-800 mb-6">{t("Privacy Policy")}</h1>
                    <p className="text-gray-600 mb-6">
                        {t("At smart traffic boost, we value your privacy and are committed to protecting your personal information. This policy outlines how we handle the collection, use, and protection of any data you provide when using our website.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">{t("What Information We Collect")}</h2>
                    <p className="text-gray-600 mb-4">
                        {t("When you use our site, we may collect the following types of information:")}
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-gray-600">
                        <li>{t("Personal details such as your name and contact information.")}</li>
                        <li>{t("Demographic data like location, preferences, and interests.")}</li>
                        <li>{t("Any other relevant information for surveys, offers, or customer feedback.")}</li>
                    </ul>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">{t("How We Use the Information")}</h2>
                    <p className="text-gray-600 mb-4">
                        {t("The data we collect is used to enhance your experience and provide tailored services, including:")}
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-gray-600">
                        <li>{t("Maintaining internal records and customer service improvements.")}</li>
                        <li>{t("Providing updates on new services, products, or special offers.")}</li>
                        <li>{t("Customizing content and website features to suit your preferences.")}</li>
                    </ul>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">{t("Security of Your Information")}</h2>
                    <p className="text-gray-600 mb-4">
                        {t("We are committed to keeping your information secure. We have implemented both technical and administrative measures to prevent unauthorized access, safeguard your data, and ensure confidentiality.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">{t("Use of Cookies")}</h2>
                    <p className="text-gray-600 mb-4">
                        {t("Cookies help us improve the user experience by analyzing web traffic and remembering preferences. These small files are stored on your device with your consent. You can modify your browser settings to decline cookies if you prefer.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">{t("External Links")}</h2>
                    <p className="text-gray-600 mb-4">
                        {t("Our site may contain links to other websites. Please note that we are not responsible for the privacy practices of these external sites. Once you leave our website, we recommend reviewing the privacy policies of any linked sites to ensure your data is protected.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">{t("Changes to This Policy")}</h2>
                    <p className="text-gray-600 mb-4">
                        {t("We may update this privacy policy from time to time. Any changes will be posted on this page, so we encourage you to review it periodically to stay informed about how we protect your information.")}
                    </p>

                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">{t("Contact Us")}</h2>
                    <p className="text-gray-600 mb-4">
                        {t("If you have any questions or concerns about this privacy policy, please reach out to us at [email/contact info].")}
                    </p>
                </div>
            </div>


        </div>
    )
}

export default Privacy