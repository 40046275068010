import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Services/Hero';
import { Helmet } from 'react-helmet';
import photo from '../images/projects-link-building-service.jpg';
import Features from '../components/Services/Features';
import Faq from '../components/Faq';
import Pricing from '../components/Services/Pricing';
function Linkbuilding() {
    const { pathname } = useLocation();
    const { t } = useTranslation()


    const linkBuildingPricings = [
    {
        plan: t("Link Building Silver Package"),
        amount: t("Link Building $49.99"),
        duration: t("Link Building 14 days"),
        popular: false,
        details: [
            t("Link Building 10 blogs with hyperlinks"),
            t("Link Building 3 hyperlinks"),
            t("Link Building Support: 7 days a week"),
            t("Link Building High domain authority, dofollow, multiple niches"),
        ],
    },
    {
        plan: t("Link Building Gold Package"),
        amount: t("Link Building $69.99"),
        duration: t("Link Building 21 days"),
        popular: false,
        details: [
            t("Link Building 15 blogs with hyperlinks"),
            t("Link Building 5 hyperlinks"),
            t("Link Building Support: 7 days a week"),
            t("Link Building High domain authority, dofollow, multiple niches"),
        ],
    },
    {
        plan: t("Link Building Platinum Package"),
        amount: t("Link Building $99.99"),
        duration: t("Link Building 30 days"),
        popular: true,
        details: [
            t("Link Building 20 blogs with hyperlinks"),
            t("Link Building 10 hyperlinks"),
            t("Link Building Support: 7 days a week"),
            t("Link Building High domain authority, dofollow, multiple niches"),
        ],
    },
    {
        plan: t("Link Building VIP Package"),
        amount: t("Link Building $199.99"),
        duration: t("Link Building 60 days"),
        popular: false,
        details: [
            t("Link Building 50 blogs with hyperlinks"),
            t("Link Building 20 hyperlinks"),
            t("Link Building Support: 7 days a week"),
            t("Link Building High domain authority, dofollow, multiple niches"),
        ],
    },
    {
        plan: t("Link Building Elite Package"),
        amount: t("Link Building $499.99"),
        duration: t("Link Building 1 year"),
        popular: false,
        details: [
            t("Link Building 200 blogs with hyperlinks"),
            t("Link Building 50 hyperlinks"),
            t("Link Building Support: 7 days a week"),
            t("Link Building High domain authority, dofollow, multiple niches"),
        ],
    },
];


    const linkBuildingReasons = [
        { title: t('Link Building High-Quality Backlinks'), description: t('Link Building We focus on obtaining links from authoritative websites within your niche.') },
        { title: t('Link Building Improved Domain Authority'), description: t("Link Building Increase your website's credibility and ranking potential.") },
        { title: t('Link Building Organic Traffic Boost'), description: t('Link Building Experience sustained organic traffic growth that benefits your SEO in the long run.') },
        { title: t('Link Building Custom Strategy'), description: t('Link Building Each business is unique, and so is our approach. We tailor every campaign to your specific needs.') }
    ];
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scroll smooth
        });
    }, [pathname]);
    return (
        <div className="h-full w-full">
            <Helmet>
                <title>{t("Meta Title Services")}</title>
                <meta name="description" content={t("Meta Description Services")} />
            </Helmet>
            <Hero
                title={t("Link Building High-Quality Link Building Services")}
                description={t("Link Building Strengthen your website’s SEO with our trusted link building services. We help you secure powerful backlinks from reputable sources to enhance your search engine rankings.")}
                photo={photo}
            />
            <Features
                title={t("Link Building Powerful Link Building to Boost Your SEO")}
                description={t("Link Building Supercharge your website’s authority with high-quality backlinks. Our strategic link-building service connects you with trusted websites to help you rank higher and grow organically.")}
                features={linkBuildingReasons}
            />
            <Pricing
                title={t("Link Building Packages")}
                description={t(
                    "Boost your website’s authority and traffic with high-quality backlinks from blogs across multiple niches. All our packages include hyperlinks from high domain authority sites, dofollow links, and permanent placement."
                )}
                pricings={linkBuildingPricings}
            />



            <Faq />

        </div>
    )
}

export default Linkbuilding