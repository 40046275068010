import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Services/Hero';
import { Helmet } from 'react-helmet';
import photo from '../images/Guest-Blogging.jpg';
import Features from '../components/Services/Features';
import Faq from '../components/Faq';
import Pricing from '../components/Services/Pricing';
function GuestBloging() {
    const { pathname } = useLocation();
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scroll smooth
        });
    }, [pathname]);


    const guestBloggingPricings = [
    {
        plan: t("Guest Blogging 1 Blog"),
        amount: t("Guest Blogging $29.99"),
        duration: t("Guest Blogging One-Time"),
        popular: false,
        details: [
            t("Guest Blogging 1 blog published on a high domain authority website (DA40+)"),
            t("Guest Blogging Guaranteed traffic growth"),
            t("Guest Blogging Improves online visibility"),
        ],
    },
    {
        plan: t("Guest Blogging 3 Blogs"),
        amount: t("Guest Blogging $49.99"),
        duration: t("Guest Blogging One-Time"),
        popular: true,
        details: [
            t("Guest Blogging 3 blogs published on high domain authority websites (DA40+)"),
            t("Guest Blogging Guaranteed traffic growth"),
            t("Guest Blogging Improves online visibility"),
        ],
    },
    {
        plan: t("Guest Blogging 7 Blogs"),
        amount: t("Guest Blogging $99.99"),
        duration: t("Guest Blogging One-Time"),
        popular: false,
        details: [
            t("Guest Blogging 7 blogs published on high domain authority websites (DA40+)"),
            t("Guest Blogging Guaranteed traffic growth"),
            t("Guest Blogging Improves online visibility"),
        ],
    },
];



    const guestBloggingReasons = [
  {
    title: t("Guest Blogging Exposure to High-Authority Sites"),
    description: t("Guest Blogging We help you get published on high-traffic blogs, expanding your reach and boosting credibility."),
  },
  {
    title: t("Guest Blogging Strategic Content Placement"),
    description: t("Guest Blogging We place your content where it matters, ensuring it reaches relevant and engaged audiences."),
  },
  {
    title: t("Guest Blogging Boost Domain Authority"),
    description: t("Guest Blogging Each guest post comes with valuable backlinks that help increase your domain authority over time."),
  },
  {
    title: t("Guest Blogging Establish Thought Leadership"),
    description: t("Guest Blogging Gain recognition as an expert in your field by sharing insightful content on reputable platforms."),
  }
];

    return (
        <div className="h-full w-full">
            <Helmet>
                <title>{t("Meta Title Services")}</title>
                <meta name="description" content={t("Meta Description Services")} />
            </Helmet>
            <Hero
                title={t("Guest Blogging Expand Your Reach with Guest Blogging")}
                description={t("Guest Blogging Boost your brand's online presence by publishing guest posts on high-authority websites. Our guest blogging service helps you secure valuable backlinks and increase your site's visibility.")}
                photo={photo}
            />
            <Features
                title={t("Guest Blogging Reach New Audiences with Targeted Guest Blogging")}
                description={t("Guest Blogging Position yourself as a thought leader and grow your influence by publishing on high-authority blogs. We help you land strategic guest posts that not only drive traffic but also boost your SEO.")}
                features={guestBloggingReasons}
            />
            <Pricing title={t("Guest Blogging Packages")} description={t("Guest Blogging Publish your content on high domain authority (DA40+) websites with guaranteed traffic growth. All our guest blogging packages help you achieve better online visibility.")} pricings={guestBloggingPricings} />

            <Faq />

        </div>
    )
}

export default GuestBloging