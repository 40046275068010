import React from 'react'
import photo from '../images/whyus.avif';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function WhyUs() {
    const { t } = useTranslation()
    const savedLanguage = localStorage.getItem('i18nextLng') || process.env.REACT_APP_DEFAULT_LANGUAGE;

    return (
        <section className="py-24 relative xl:mr-0 lg:mr-5 mr-0">
            <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
                <div className="w-full justify-start items-center xl:gap-12 gap-10 grid lg:grid-cols-2 grid-cols-1">
                    <div className="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
                        <div className="w-full flex-col justify-center items-start gap-8 flex">
                            <div className="flex-col justify-start lg:items-start items-center gap-4 flex">
                                <h6 className="text-gray-400 text-base font-normal leading-relaxed">{t("About Us")}</h6>
                                <div className="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                                    <h2 className="text-primary text-4xl font-bold font-manrope leading-normal lg:text-start text-center">
                                        {t("Why smart traffic boost ?")} {process.env.REACT_APP_SITE_NAME} ?</h2>
                                    <p className="text-gray-500 text-base font-normal leading-relaxed lg:text-start text-center">
                                        {t("At")} {process.env.REACT_APP_SITE_NAME}   {t("we believe in quality over quantity. Every backlink we create is designed to be powerful and authentic, ensuring it adds real value to your website’s ranking. With years of experience in SEO, our strategies are crafted to deliver sustainable, long-term growth that drives success. ")} </p>
                                </div>
                            </div>
                            <div className="w-full flex-col justify-center items-start gap-6 flex">
                                <div className="w-full justify-start items-center gap-8 grid md:grid-cols-2 grid-cols-1">
                                    <div className="w-full h-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                                        <h4 className="text-gray-900 text-xl font-bold font-manrope leading-9">{t('10+ Years')}</h4>
                                        <p className="text-gray-500 text-base font-normal leading-relaxed">{t('Boosting Online Visibility with Proven Results')}</p>
                                    </div>
                                    <div className="w-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                                        <h4 className="text-gray-900 text-xl font-bold font-manrope leading-9">{t('500+ Successful Projects')}</h4>
                                        <p className="text-gray-500 text-base font-normal leading-relaxed">{t('Delivering Results Across Various Industries')}</p>
                                    </div>

                                </div>

                                <div className="w-full h-full justify-start items-center gap-8 grid md:grid-cols-2 grid-cols-1">
                                    <div className="w-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                                        <h4 className="text-gray-900 text-xl font-bold font-manrope leading-9">{t('300+ Successful Partnerships')}</h4>
                                        <p className="text-gray-500 text-base font-normal leading-relaxed">{t('Collaborating with Brands to Drive Growth')}</p>
                                    </div>

                                    <div className="w-full h-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                                        <h4 className="text-gray-900 text-xl font-bold font-manrope leading-9">{t('98% Satisfied Clients')}</h4>
                                        <p className="text-gray-500 text-base font-normal leading-relaxed">{t('Our Commitment to Delivering Top-notch Results')}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='flex justify-start items-center gap-3 w-full'>
                            <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-800 border md:px-5 px-2.5 py-2.5 rounded-full bg-white/10 text-gray-800  font-[400] text-[16px] w-fit '>
                                <span>{t("Let’s Get Started")}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                            </Link>
                            <a href={process.env.REACT_APP_DACHBOARD_LINK} className='flex justify-center items-center gap-2 capitalize border-primary border md:px-5 px-2.5 py-2.5 rounded-full bg-secondary text-primary font-[500] text-[16px] w-fit'>
                                <span>{t("Dashboard")}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                                    <path d="M7 7h10v10" />
                                    <path d="M7 17 17 7" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="w-full lg:justify-start justify-center items-start flex">
                        <div className="sm:w-[564px] w-full sm:h-[646px] h-full sm:bg-gray-100 rounded-3xl sm:border border-gray-200 relative">
                            <img className={`sm:mt-5 ${savedLanguage == 'ar' ? 'sm:mr-5' : 'sm:ml-5'}  w-full h-full rounded-3xl object-cover`} src={photo} alt="about Us image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default WhyUs