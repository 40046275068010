
import React from 'react'
import { Link } from 'react-router-dom';

function BlogCard({ post, key }) {
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return new Date(dateString).toLocaleString('en-US', options); // Customize locale as needed
    };
    return (
        <Link to={`/Post/${post.slug}`} key={key} className="flex w-full cursor-pointer items-end overflow-hidden border shadow rounded-xl border-gray-300 h-96 relative group">
            <img
                src={post.image}
                alt="Blog post image"

                className="rounded-lg h-full w-full object-cover"
            />
            <div className="absolute inset-0 bg-primary opacity-50 z-10 transition-opacity group-hover:opacity-60"></div>

            <div className="w-full z-50 absolute px-4 py-4 overflow-hidden rounded-b-lg ">
                <h2 className="md:mt-4 md:text-[24px] text-[20px] font-[700] text-gray-100 capitalize line-clamp">{post.title}</h2>
                <p className=" mt-1    md:text-[16px] text-[14px] font-[500] text-gray-100 capitalize overflow-hidden line-clamp ">
                    {post.description}
                </p>

                <div className='flex justify-start items-center  mt-1   md:text-[14px] text-[12px] gap-2 leading-[24px] font-[600] text-gray-100 capitalize'>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-calendar-fold"><path d="M8 2v4" /><path d="M16 2v4" /><path d="M21 17V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11Z" /><path d="M3 10h18" /><path d="M15 22v-4a2 2 0 0 1 2-2h4" /></svg> <span />
                    </div>
                    <span>{formatDate(post.created_at)}</span>
                </div>
            </div>
        </Link>
    )
}

export default BlogCard