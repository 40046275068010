import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BlogSection = ({ blogs }) => {
    const { t } = useTranslation()

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return new Date(dateString).toLocaleString('en-US', options); // Customize locale as needed
    };

    return (
        <section className="py-24 bg-primary">
            <div className="mx-auto grid place-items-center xl:px-24 lg:px-10 md:px-5 px-3">
                <h1 className='md:text-[48px] text-[30px]  font-[800] leading-[40px] capitalize md:leading-[80px] text-gray-100'>
                    {t("Insigths , Inspirations")} <span className='text-secondary'>{t("and Updates")} </span>
                </h1>
                <p className='md:text-[18px] text-[14px] font-[400] mt-3 text-center leading-[25px] md:leading-[40px] text-gray-100'>
                    {t("Welcome to our blog where we share insights, tips, and stories about our services and industry trends.")}
                </p>               
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 w-full gap-4 py-14 ">
                    {blogs.slice(0, 4).map(item => {
                        return (
                            <Link to={`/Post/${item.slug}`} className="group cursor-pointer w-full relative   border border-neutral-700 bg-white/10 rounded-2xl p-5 transition-all duration-300 hover:border-secondary">
                                <div className="flex items-center mb-6">
                                    <img src={item.image} alt="Harsh image" className="rounded-lg h-56 w-full object-cover" />
                                </div>
                                <div className="block">
                                    <h4 className="text-neutral-200 font-medium leading-8 mb-9">{item.title}</h4>

                                    
                                </div>

                                <div className="flex absolute rigth-3 bottom-3 items-center justify-end  font-medium">
                                        <span className="text-sm text-secondary">{formatDate(item.schedule_at)}</span>
                                    </div>
                            </Link>
                        )
                    })
                    }


                </div>

                <div className='flex justify-center items-center gap-3 w-full'>

                    <Link to='/Blog' className='flex justify-center items-center gap-2 capitalize border-primary border md:px-10 px-2.5 py-2.5 rounded-full bg-secondary text-primary font-[500] text-[16px] w-fit'>
                        <span>{t("Read more")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                            <path d="M7 7h10v10" />
                            <path d="M7 17 17 7" />
                        </svg>
                    </Link>
                </div>  </div>
        </section>


    );
};

export default BlogSection;
