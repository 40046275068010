import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Services/Hero';
import { Helmet } from 'react-helmet';
import photo from '../images/PPC2018.webp';
import Features from '../components/Services/Features';
import Pricing from '../components/Services/Pricing';
import Faq from '../components/Faq';
function ContentCreation() {
    const { pathname } = useLocation();
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

   const contentWritingPricings = [
    {
        plan: t("Content Writing 1 Blog"),
        amount: t("Content Writing $49.99"),
        duration: t("Content Writing 3 Days"),
        popular: false,
        details: [
            t("Content Writing One SEO-friendly blog (1000 words)"),
            t("Content Writing Includes H1 to H4 tags and keyword research"),
            t("Content Writing Delivered in 3 days"),
        ],
    },
    {
        plan: t("Content Writing 3 Blogs"),
        amount: t("Content Writing $99.99"),
        duration: t("Content Writing 1 Week"),
        popular: true,
        details: [
            t("Content Writing Three SEO-friendly blogs (1000 words each)"),
            t("Content Writing Includes H1 to H4 tags and keyword research"),
            t("Content Writing Delivered in 1 week"),
        ],
    },
    {
        plan: t("Content Writing 10 Blogs"),
        amount: t("Content Writing $249.99"),
        duration: t("Content Writing 2 Weeks"),
        popular: false,
        details: [
            t("Content Writing Ten SEO-friendly blogs (1000 words each)"),
            t("Content Writing Includes H1 to H4 tags and keyword research"),
            t("Content Writing Delivered in 3 days per blog or all in 2 weeks"),
        ],
    },
];



    const contentWritingReasons = [
        {
            title: t("Content Writing Tailored Content for Your Audience"),
            description: t("Content Writing We craft content that speaks directly to your target audience, ensuring every piece resonates and engages."),
        },
        {
            title: t("Content Writing SEO-Optimized for Maximum Visibility"),
            description: t("Content Writing All content is strategically optimized to rank higher in search engines, boosting organic traffic."),
        },
        {
            title: t("Content Writing Diverse Formats and Styles"),
            description: t("Content Writing Whether it's blogs, articles, or web copy, we deliver content in various formats to suit your brand's needs."),
        },
        {
            title: t("Content Writing Consistent Quality and Delivery"),
            description: t("Content Writing Rely on our expert writers to produce high-quality content on time, every time, helping you maintain a steady content flow."),
        }
    ];

    return (
        <div className="h-full w-full">
            <Helmet>
                <title>{t("Meta Title Services")}</title>
                <meta name="description" content={t("Meta Description Services")} />
            </Helmet>
            <Hero
                title={t("Content Writing Professional Content Writing Services")}
                description={t("Content Writing Engage your audience with compelling, SEO-optimized content tailored to your brand. Our expert writers deliver high-quality blog posts, articles, and web copy that drive results.")}
                photo={photo}
            />
            <Features
                title={t("Content Writing Compelling Content That Drives Results")}
                description={t("Content Writing Elevate your brand’s voice with content that informs, engages, and converts. From blog posts to product descriptions, we craft words that captivate your audience and fuel your growth.")}
                features={contentWritingReasons}
            />
            <Pricing title={t("Content Writing On-Demand Content Writing Packages")} description={t("Content Writing Our SEO-friendly content writing services ensure that your blogs are optimized for search engines, complete with keyword research and proper header structures.")} pricings={contentWritingPricings} />
            <Faq />
        </div>
    )
}

export default ContentCreation