import React, { useState } from 'react'
import photo from '../../images/whyus.avif';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

function Form() {
    const { t } = useTranslation()
    const savedLanguage = localStorage.getItem('i18nextLng') || process.env.REACT_APP_DEFAULT_LANGUAGE;
    const language = process.env.REACT_APP_LANGUAGE_SWITCH;

    const [formData, setFormData] = useState({ name: null, email: null, message: null, phone: null });
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(false);
    const [color, setColor] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const getUrl = () => {
        if (savedLanguage == 'ar') {
            return `backlinksarab`
        } else if (savedLanguage == 'fr') {
            return 'seofocus'
        } else if (savedLanguage == 'en' && language === 'arabic') {
            return `backlinksarab`

        } else if (savedLanguage == 'en' && language !== 'arabic') {
            return 'SmartTrafficBoost'
        }
    }

    const save = async (e) => {
        e.preventDefault();
        if (!formData.name || !formData.email || !formData.message || !formData.phone) {
            setMessage('All fields are required');
            setColor('yellow')
        }
        try {
            setLoading(true);

            const response = await axios.post(`https://api.dashforme.xyz/public/api/leads/create/${getUrl()}`, formData);
            const responseData = response.data;

            if (responseData) {
                setMessage(responseData.message);
                setColor('green')
                setLoading(false);

            }
        } catch (error) {
            console.error('Error fetching post data:', error);
        } finally {
            setLoading(false);
        }
    }

    const colorClasses = {
        yellow: {
            border: 'border-yellow-600',
            text: 'text-yellow-600',
            bg: 'bg-yellow-50',
        },
        green: {
            border: 'border-green-600',
            text: 'text-green-600',
            bg: 'bg-green-50',
        },
    };

    return (
        <div className='flex justify-center md:flex-row flex-col px-5 mx-auto items-center py-12 md:py-[8rem] 2xl:w-[75%] xl:w-[85%] lg:w-[95%] md:w-[100%] gap-5 md:gap-24 w-full h-full'>

            <div className="w-full lg:justify-start justify-center items-start flex">
                <div className="sm:w-[564px] w-full sm:h-[646px] h-full sm:bg-gray-100 rounded-3xl sm:border border-gray-200 relative">
                    <img className={`sm:mt-5 ${savedLanguage == 'ar' ? 'sm:mr-5' : 'sm:ml-5'}  w-full h-full rounded-3xl object-cover`} src={photo} alt="about Us image" />
                </div>
            </div>

            <div className='flex justify-end flex-col items-start h-full  gap-4 h-full  w-full'>

                <div className='flex md:text-[18px] text-[17px] gap-3 font-[400] leading-[30px] justify-end flex-col items-start h-full  W-full'>
                    <h1 className='md:text-[45px] text-[25px] font-[900] md:leading-[50px]  leading-[40px]  text-primary capitalize'>
                        {t("Send Us Message")}
                    </h1>
                    <p className='text-primary text-[15px] font-[400] capitalize'>
                        {t("fill up the form and our team will get back to you within 24 hours.")}
                    </p>

                </div>
                {message && (
                    <div className={`flex justify-center items-center px-5 py-3 border-[1.5px] text-[18px] font-[600] w-full rounded-md ${colorClasses[color]?.border} ${colorClasses[color]?.text} ${colorClasses[color]?.bg}`}>
                        {message}
                    </div>
                )}

                <form className='w-full'>

                    <div className='flex w-full justify-center items-start flex-col gap-4'>

                        <div className='flex justify-center text-primary  w-full items-start flex-col gap-2'>
                            <label className='text-[16px] font-[600]'>{t("Your Name *")}</label>
                            <input type='text' onChange={(e) => handleChange(e)} name='name' className='w-full h-14 rounded-md outline-none flex px-4 bg-gray-100 text-primary placeholder-gray-500 ' placeholder={t("Enter your name ...")} />

                        </div>

                        <div className='flex justify-center text-primary  w-full items-start flex-col gap-2'>
                            <label className='text-[16px] font-[600]'>{t("Email *")}</label>
                            <input type='email' onChange={(e) => handleChange(e)} name='email' className='w-full h-14 rounded-md outline-none flex px-4 bg-gray-100 text-primary placeholder-gray-500 ' placeholder={t("Enter your email  ...")} />

                        </div>


                        <div className='flex justify-center text-primary  w-full items-start flex-col gap-2'>
                            <label className='text-[16px] font-[600]'>{t("Phone Number *")}</label>
                            <input type='text' onChange={(e) => handleChange(e)} name='phone' className='w-full h-14 rounded-md outline-none flex px-4 bg-gray-100 text-primary placeholder-gray-500 ' placeholder={t("Enter Phone Number...")} />

                        </div>


                        <div className='flex justify-center text-primary  w-full items-start flex-col gap-2'>
                            <label className='text-[16px] font-[600]'>{t("Message *")}</label>
                            <textarea rows={4} onChange={(e) => handleChange(e)} name='message' className='w-full py-4 rounded-md outline-none flex px-4 bg-gray-100 text-primary placeholder-gray-500 ' placeholder={t("Enter your message ...")}></textarea>
                        </div>



                        <button onClick={(e) => save(e)} className='flex  justify-center items-center gap-2 capitalize border-primary border px-5 py-2.5 rounded-full bg-primary text-secondary font-[500] mt-5 text-[16px]  w-full'>
                            {
                                loading ?

                                    <div role="status">
                                        <svg aria-hidden="true" className="w-6 h-6 text-white/20 animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <>
                                        <span>{t("Send Message")}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                                            <path d="M7 7h10v10" />
                                            <path d="M7 17 17 7" />
                                        </svg>
                                    </>

                            }

                        </button>

                    </div>

                </form>


            </div>



        </div>)
}

export default Form