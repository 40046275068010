import React, { useEffect } from 'react'
import Hero from '../components/Contact/Hero'
import Form from '../components/Contact/Form'
import Faq from '../components/Faq'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function Contact() {
    const { t } = useTranslation()


    const { pathname } = useLocation();

    useEffect(() => {
 window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scroll smooth
    });    }, [pathname]);


    return (
        <div className="h-full w-full">
            <Helmet>
                <title>{t("Meta Title Contact")}</title>
                <meta name="description" content={t("Meta Description Contact")} />
            </Helmet>
            <Hero />
            <Form />
            <Faq />

        </div>
    )
}

export default Contact