import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import photo from '../images/photo.jpg';
import { useTranslation } from 'react-i18next';

function OtherServices() {
    const { pathname } = useLocation();
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scroll smooth
        });
    }, [pathname]);
    return (
        <div>OtherServices</div>
    )
}

export default OtherServices